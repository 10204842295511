@import '../bootstrap/dist/css/bootstrap.min.css';
@import '../font-awesome/css/font-awesome.min.css';
@import '../assets/css/atlantis.css';
@import '../assets/css/atlantis.min.css';
@import '../assets/css/demo.css';
@import '../assets/css/fonts.css';
@import '../assets/css/fonts.min.css';

@font-face {
  font-family: "Maison Neue Bold";
  src: url('../fonts/Maison-Neue-Bold.eot?') format('eot'),
    url("../fonts/Maison-Neue-Bold.ttf") format('truetype'),
    url("../fonts/Maison-Neue-Bold.woff") format("woff"),
    url("../fonts/Maison-Neue-Bold.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue Mono";
  src: url('../fonts/Maison-Neue-mono.eot?') format('eot'),
    url("../fonts/Maison-Neue-mono.ttf") format('truetype'),
    url("../fonts/Maison-Neue-mono.woff") format("woff"),
    url("../fonts/Maison-Neue-mono.woff2") format("woff2");
  font-style: normal;
}

body {
  font-family: Maison Neue Mono;
  font-style: bold !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.main-header-color {
  background: #1E2431 !important;
  height: 32px !important;
  min-height: 85px !important;
  margin: 1.5% 0 0 0;
  padding: 15px 0 0 0;
}

.main-header-top-color {
  background: #FFF !important;
  height: 32px !important;
  min-height: 25px;
}

.bande_blanche_t {
  background: #FFF !important;
  height: 2px !important;
  min-height: 25px;
  margin: 5.05rem 0 0 0;
}

/*Debut du positionnement du dropdown du top header*/
.main-header-top-color ul {
  line-height: 1px;
}

.main-header-top-color .navbar-header {
  min-height: 2px !important;
}

.main-header-top-color .css-2b097c-container {
  color: black !important;
  border: 1px solid #FFF !important;
  border-radius: 5px;
}

.main-header-top-color .css-1wa3eu0-placeholder {
  position: relative !important;
  color: #1E2431 !important;
}

.css-tlfecz-indicatorContainer {
  color: #1E2431 !important;
}

.main-header-top-color .css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  align-self: stretch;
  /*background-color: hsl(0,0%,80%);*/
  background-color: #FFF;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.main-header-top-color .css-g1d714-ValueContainer {
  padding: 0px !important;
}

.main-header-top-color .css-1uccc91-singleValue {
  overflow: initial;
}

.main-header-top-color .css-1pahdxg-control {
  border-color: #FFF !important;
  box-shadow: 0 0 0 1px #FFF !important;
}

.main-header-top-color .css-1pahdxg-control:hover {
  border-color: #FFF !important;
}

.main-header-top-color .css-1pahdxg-control {
  max-height: 25px !important;
  min-height: 20px !important;
  background-color: transparent;
}

.main-header-top-color .css-yk16xz-control {
  max-height: 25px !important;
  min-height: 20px !important;
  background-color: transparent;
  margin: -3px 0 0 0 !important;
}

/*Fin du positionnement du dropdown du top header*/
.logo-header-width {
  width: 300px !important;
  margin: 3px;
}

.main-header-color .logo-header-width {
  width: 250px !important;
  /*margin: 13px 0 0 0!important;*/
}

.main-header-color .navbar-header {
  /*margin: 7px 0 0 0!important;*/
}

.logo-header-top-width {
  width: 300px !important;
  max-height: 25px;
}

.logo {
  font-family: Maison Neue Bold !important;
  left: .3vw;
  top: .7vh;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF !important;
}

.main-header-top-color .logo-header .logo {
  height: 118% !important;
}

.logo_header_size {
  width: 2.5vw;
}

.icon_header_size {
  width: 2.5vw;
  padding: 0 50% 0 0 !important;
}

.text_header_size {
  margin: 0 1px;
  font-size: 18px;
}

.body_l_t {
  background-color: #E1FFF3;
  margin: 4.5% 0 0 0;
  background: linear-gradient(50deg, #E1FFF3, #b9babf);
  background-blend-mode: color, normal;
  z-index: 2
}

.h-100,
.h-100 .row:first-child {
  height: 100% !important;
}

.img_right_t {
  background-image: url("./../images/bgdAuth.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100% !important;
  width: 100% !important;
}

.acide_left_image_position {
  position: absolute;
  top: 14vh;
  left: 7%;
  width: 3.5vw;
}

.text_a_l_i {
  font-family: Maison Neue Bold !important;
  position: absolute;
  top: 16vh;
  left: 18%;
  font-size: 28px;
  line-height: 34px;
  color: #42a350;
}

.div_footer {
  color: #FFF;
  padding: 16px 22px;
  position: absolute;
  margin: 35px 0 0 10px;
  background: rgba(84, 166, 73, 0.03);
  border-radius: 18px;
}

.div1_footer {
  color: #FFF;
  padding: 16px 22px;
  /* position: absolute; */
  margin: 35px 0 0 5px;
  background: rgba(84, 166, 73, 0.03);
  border-radius: 18px;
  /* width: 380px; */
}

.TextBottomInputFooter_t {
  margin: .5rem 0 0 0;
  color: #ebebeb91;
}

.restezConFooter_t {
  margin: 30px 0 0 0;
}

.imageRSFooter {
  width: 42px;
  margin: 5px 10px 0 0;
}

.labelFooter_t {
  color: #FFF !important;
  display: block;
  margin: 0 0 10px 0;
}

.btnSubmitFooter_t {
  position: relative;
  margin: 0px 0 0 15px;
  right: 0;
  display: inline-block;
  background: #54a649 !important;
  border-radius: 10px;
  padding: 8px !important;
  color: #FFF;
  font-size: 12px;
  width: 105px;
}

.inputFooter_t {
  max-width: 250px;
  /* margin: -7px 0 0 0; */
  position: relative;
  padding: 8px;
  border: .5px solid #97979738;
  border-radius: 10px;
  font-size: 12px;
}

.footer_t {
  position: relative;
  /* width: 98.8%; */
  /* height: 731px; */
  background: #1E2431;
  padding: 15px 0px;
  margin: 130px 0 0 0;
}

.footerRow1Div2_t {
  margin-bottom: .7rem;
}

.footerRow1Div2Green_t {
  display: block;
  color: #54A649;
  font-size: 13px;
}

.footerRow1Div2White_t {
  display: block;
  color: #ebebebcf;
  font-size: 13px;
  margin: 0 0 25px 0;
  word-spacing: -5px;

}

.footerMarginTopRow2_t {
  /* margin: 17.5rem 0 0 0; */
  margin: .5rem 0 0 0;
}

.copyright_t {
  display: block;
  /* margin: 24rem 0 0 0; */
  margin: 1.5rem 0 0 0;
  color: #FFF;
}

.footerMobil_t {
  display: none;
}

.text_a_l_under_i {
  font-family: Maison Neue Bold !important;
  position: absolute;
  top: 30vh;
  left: 7%;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  color: #1E2431;
}

.text_a_l_under_i_2 {
  position: absolute;
  top: 38vh;
  left: 7%;
  font-size: 18px;
  line-height: 28px;
  width: 80%;
  font-weight: 500;
  color: #1E2431;
}

.rejoignez_nous {
  position: absolute;
  top: 54vh;
  left: 7%;
  width: 54px;
  height: 54px;
}

.text_rejoignez_n {
  font-family: Maison Neue Bold !important;
  position: absolute;
  top: 57vh;
  left: 18%;
  font-size: 18px;
  line-height: 18px;
  color: #1E2431;
}

.img_background {
  position: absolute;
  left: 1%;
  right: 53.07%;
  /*top: 4%;*/
  bottom: .5%;
  width: 60%;
  z-index: 1;
  opacity: 0.2;
}

.img_background1 {
  position: absolute;
  left: 0.01%;
  right: 0%;
  top: 24.23%;
  bottom: -50%;
  background: rgba(30, 36, 49, 0.05);
  transform: rotate(-30deg);
}

.sidebar,
.sidebar[data-background-color=white] {
  margin-top: 72px !important;
}

.sidebar-style-lud i:after {
  color: transparent !important;
}

.sidebar .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #54A649 !important;
}

.sidebar .nav.nav-primary>.nav-item a:focus i {
  color: #8d9498 !important;
}

.sidebar .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #54A649 !important;
}

.sidebar .nav-collapse li a,
.sidebar[data-background-color=white] .nav-collapse li a {
  padding: 1px 25px !important;
}

.sidebar .nav-collapse,
.sidebar[data-background-color=white] .nav-collapse {
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-top: 1px !important;
}

.table {
  width: 100%;
}

.table thead th {
  font-weight: 500 !important;
}

.th_t th:last-child {
  text-align: center
}

.css-2b097c-container {
  color: black !important;
  border: 1px solid #ced4da !important;
  border-radius: 5px;
}

.row_scroll_ok_t .css-2b097c-container {
  width: 8vw !important;
  display: inline-block !important;
  margin: 0 3% 0 3% !important;
}

.table_t .nav-search .input-group {
  border: 1px solid #dcdbdb !important;
  background: #ffffff !important;
  border-radius: 30px !important;
}

.table_t .class_search_t {
  padding: 8px 0 8px 10px;
  border: none !important;
  /*border-radius: 15px;*/
  width: 80%;
  height: 100%;
}

.home_t>.main-panel {
  background: #43a34f2b;
}

.datatables_t .ajust_row {
  align-items: baseline !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.margin_pag_t {
  text-align: right;
}

.header-cell {
  font-weight: 600 !important;
}

.admin_p_t .sweet-alert {
  width: 62vw !important;
}

.input_t {
  display: block;
  width: 100%;
  padding: .69rem 1rem !important;
  border: 1px solid #ced4da !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.3;
  border-radius: .25rem;
  height: inherit !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-g1d714-ValueContainer {
  padding: .5rem 1rem;
}

.amin_pays .css-g1d714-ValueContainer {
  padding: 0px !important;
}

.css-yk16xz-control {
  border-style: hidden !important;
  border-radius: 60px !important;
}

ul {
  list-style-type: none;
  line-height: 35px;
}

.c_admin_pays_ul {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid green;
  border-radius: 10px;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 30px !important;
  /*padding-inline-start: 20px!important;*/
  padding: 10px 60px 10px 20px !important;
  z-index: 2;
}

.form-row_2_t {
  margin: 20px 0 0 0;
}

.params_min_height {
  min-height: 120px
}

.actif {
  color: #54A649 !important;
}

a {
  text-decoration: none !important;
}

fieldset {
  width: 100%;
  border: 1px solid #57596240 !important;
  padding: 0 .3em 1.4em .7em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend {
  font-size: 1em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

label.field {
  text-align: left;
  width: 100px;
  float: left;
  font-weight: bold;
  color: red;
}

.btn_direction_t {
  background: #FFF !important;
  color: #54A649 !important;
  border: .5px solid #54A649;
  border-radius: 25px;
  margin: 20px 15px 0 0;
  box-shadow: 0px 0px 2px 2px #54a64933;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

a.disabled_t {
  opacity: .65;
  /*pointer-events: none;*/
}

a.disabled_t:hover {
  cursor: not-allowed !important;
}

/*.css-2b097c-container2_t {
  position: relative!important;
  margin: 0 0 20vh 0!important;
  z-index: 5601!important;
}*/
.css-2613qy-menu {
  position: relative !important;
  margin: 0 0 50% 0 !important;
  color: black;
  display: none !important;
  background-color: red !important;
  z-index: 5601
}

.admin_p_t .error_t span {
  font-size: 11px
}

.react-tel-input .form-control {
  width: 100% !important;
  line-height: 21px !important;
  border: none !important;
  /* est ajouter pour retirer la bordure sur input phone */
  /* width: 150px!important;  */
}

.css-1uccc91-singleValue {
  font-size: 13px !important;
}

.pwd_reinit {
  padding: 3px 10px 3px 10px !important;
  border-radius: 5px;
}

.loadingLogin div:first-child {
  fill: #54a649 !important;
  /*fill: #FFF!important;*/
  /*fill: #1e2431!important;*/
  height: 1.4% !important;
  width: 1.4% !important;
  margin: -.9% 0 0 -1.2% !important;
  position: absolute !important;
}

/*Debut de laPartie qui fait en sorte 
que menu de selections survole la modale*/
.sweet-alert {
  overflow: initial !important;
}

/*Fin de laPartie qui fait en sorte 
que menu de selections survole la modale*/
.class_bnt_t {
  padding: .4rem 1.4rem !important;
  border-radius: 7px !important;
}

.error_Compte_P {
  border-top: 1px solid #cececece;
  border-right: 1px solid #cececece;
  border-bottom: 1px solid #cececece;
  border-left: 4px solid red;
  padding: 3% 0 3% 0%;
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 600;
  background-color: #ffc0cb33;
}

.forgot_pwd_text {
  width: 70%;
  text-align: center;
  margin: 10% 0 12% 5%;
}

.eyeshow {
  display: block;
}

.eyehide {
  display: none;
}

.eye-password svg {
  width: 20px;
  position: absolute;
  right: 25px;
  bottom: 27%;
  color: #868686;
  fill: #868686;
}

svg:not(:root) {
  overflow: hidden;
}

a>svg {
  transition: all .15s ease;
}

.bnt_and_load_t {
  position: absolute;
  left: 40%;
  font-size: 17px;
  margin: -2px 0 0 0;
}

.cancel_btn_t {
  position: absolute;
  top: 5px;
  right: 10px;
}

.nav_item_width {
  /*width: 100px;*/
  text-align: center;
  line-height: 5px;
  font-size: 12px;
  margin: 0 50px 0 50px;
}

.home_pres_t .btn {
  padding: .4rem 1.4rem !important;
}

/*.pad_t {
  padding: 50px 0 0 0; 
}*/
.cart_t {
  margin: -10px 0 0 0;
  color: #FFF;
}

.li_img_t {
  width: 17px;
  color: #FFF;
}

.btn_auth_t {
  border-radius: 12px;
}

.vendez_text_css_t {
  font-weight: bold;
  line-height: 62px;
  font-size: 45px;
  color: #1E2431;
}

.home_ac_lf {
  font-family: Maison Neue Bold !important;
  padding: 0 0 0 3%;
}

.text_1_css_t {
  font-size: 18px;
  color: #1E2431;
}

.coast_t {
  color: #FFF;
  border-radius: 25px;
  padding: .65rem 1rem !important;
  background-color: #1E2431;
  position: absolute;
  right: 85px;
  font-size: 10px;
}

/*Debut Search Boutton*/
.search-input {
  border: .5px solid #97979738;
  border-radius: 15px;
  height: 37px;
  width: 350px
}

.search-bar .form-group.with-button input {
  height: 100%;
  border: none;
  color: #b2b2b2;
  background-color: transparent;
}

.form-group.with-button input {
  padding-right: 50px;
  padding-left: 15px;
}

.search-input>.form-control {
  padding-bottom: 20px;
  padding-top: 1px;
  font-size: 12px;
}

.search-bar .form-group.with-button button {
  color: #42a350;
  fill: #42a350;
  background-color: transparent;
  width: 50px;
  border-radius: 0 10px 10px 0;
}

.form-group.with-button button {
  display: block;
  position: absolute;
  right: 13px;
  top: 0;
  height: 100%;
  width: 35px;
  text-align: center;
  line-height: 100%;
  color: #fff;
  fill: #fff;
  background-color: #d7d9e5;
  border: none;
}

.search-bar .form-group.with-button button svg {
  height: 22px;
  width: 22px;
}

.search_t {
  width: 17px;
}

/*Fin Search Boutton*/

.secondDivShop_t {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 28px;
  position: static;
  width: 10%;
  height: 45px;
  left: 0px;
  top: 0px;
  background: transparent;
  border: 1px solid #1E2431;
  box-sizing: border-box;
  border-radius: 10px;
  color: #1E2431;
  font-size: 13px;
  margin: 0 0 0 15px;
}

.secondDiv_t {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 28px;
  position: static;
  width: 18%;
  height: 50px;
  left: 0px;
  top: 0px;
  background: transparent;
  border: 1px solid #1E2431;
  box-sizing: border-box;
  border-radius: 10px;
  color: #1E2431;
  font-size: 13px;
  margin: 0 0 0 15px;
}

.textDispo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
}

.smallDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;

  position: static;
  width: 32px;
  height: 30px;
  left: 127px;
  top: 22px;

  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
}

.smallDiv3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;

  position: static;
  width: 44px;
  height: 30px;
  left: 77px;
  top: 22px;
  color: #FFF;

  background: rgba(30, 36, 49, 0.9);
  border-radius: 8px;
}

.smallDiv2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;

  position: static;
  width: 32px;
  height: 30px;
  left: 127px;
  top: 22px;

  background: #EBEBEB;
  border-radius: 8px;
}

.search-input-relay-point,
.search-input-RP-shop {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  padding: 8px;
  position: static;
  height: 45px !important;
  left: 0;
  top: 0;
  border-radius: 19px;
  border: 0.5px solid #1e2431;
  margin: 0 0 0 -1rem;
}

.search-bar .form-group.with-buttonCmd input {
  font-family: "Maison Neue Bold" !important;
  font-size: 15px;
  height: 100%;
  border: none;
  color: #000;
  background-color: transparent;
}

.form-group.with-buttonCmd input {
  padding-right: 15px;
  padding-left: 40px;
}

.search-input-RP-shop input {
  margin-top: -.5rem;
}

.search-input-RP-shop {
  width: 100%;
}

.search-bar .form-group.with-buttonCmd button {
  color: #1e2431;
  fill: #1e2431;
  background-color: transparent;
  width: 50px;
  max-height: 50px;
  border-radius: 0 10px 10px 0;
}

.form-group.with-buttonCmd button {
  display: block;
  position: absolute;
  left: 3px;
  top: 0;
  height: 100%;
  width: 35px;
  text-align: center;
  line-height: 100%;
  color: #fff;
  fill: #fff;
  background-color: #d7d9e5;
  border: none;
}

.offline_t {
  font-family: "Maison Neue Bold";
  display: inline-block;
  border-left: 3px solid #54a649;
  padding: .2rem 0 .2rem .5rem;
  font-size: 13px;
  color: #E91339;
  margin: .5rem 0 -25px 0;
}

/*Stat Register */
.register_t .div_register_t {
  background: #FFF;
  /*width: 400px;*/
  max-width: 41.666667%;
  margin: 0 0 0 3%;
  height: 500px;
  border-radius: 15px;
}

.header_f_reg_t {
  font-weight: 700;
  font-size: 16px;
  font-family: Maison Neue Bold !important;
  margin: 3% 0 0 7% !important;
  color: #1E2431;
}

.input_float_posit_t {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #1E2431 !important;
}

.input_g_2 .form-control.filled+.placeholder {
  transform: translate3d(0, -20px, 0);
}

.input_g_1 .form-control.filled+.placeholder {
  transform: translate3d(0, -6px, 0);
}

.input_float_posit_t.form-control {
  border-radius: 0px !important;
  padding: .6rem .1rem .2rem .1rem !important;
}

.input_float_posit_t.form-control.input-solid {
  background: #FFF !important;
}

.div_register_t .form-floating-label .placeholder {
  padding: .375rem .1rem !important;
  font-size: 90% !important;
}

.div_register_t .form-floating-label .form-control.filled+.placeholder {
  color: #838383 !important;
  opacity: .6;
}

.input_g_2 .input-group-text {
  background-color: transparent !important;
  border-color: #FFF !important;
}

.input_g_2 .input_float_posit_t.form-control {
  z-index: 3 !important;
}

.eye_float_posit_t {
  /*margin: 9.5px 0 0 -13px;*/
  position: absolute;
  width: 12%;
  right: 5px;
  top: 10px;
  z-index: 3;
}

.eye_float_posit_t .input-group-text .fa {
  font-size: 15px !important;
}

.register_t .perso_label {
  font-family: "Maison Neue Bold" !important;
  font-size: 12px !important;
}

.perso_label2 {
  font-family: "Maison Neue Bold" !important;
  font-size: 11px !important;
}

.select_t {
  padding: 2% 0 2% 0;
}

.register_t .row_t {
  margin: 2% 0 0 0;
}

.label_select_t {
  font-size: 12px !important;
  color: #838383 !important;
  font-weight: 400;
  opacity: .6;
}

.select_t .css-2b097c-container {
  background-color: #1E2431;
  border-color: #1E2431;
  border-radius: 10px !important;
  color: #FFF !important;
}

.select_t .css-1okebmr-indicatorSeparator {
  width: 0px;
}

/*Debut de la gestion de la Hauteur select*/
.select_t .css-yk16xz-control {
  background-color: #1E2431;
  max-height: 44px !important;
  min-height: 18px;
  height: 44px;
}

/* .z-index-data-picker-t {
  z-index: 1002 !important;
} */
.react-datepicker-popper {
  z-index: 1002 !important;
}

/*Fin de la gestion de la Hauteur select*/
/*Debut de la gestion du texte dans select*/
.select_t .css-1wa3eu0-placeholder {
  top: 40% !important;
  transform: translateY(-40%) !important;
  padding: 0 0 0 2%;
  font-size: 12px;
  padding: 0 0 0 10px;
}

.input_phone_regist_t .form-control {
  /* padding: .3rem 25px !important; */
  /* padding: .3rem 0 !important; */
  border-radius: 10px !important;
  background: #1E2431 !important;
  color: #FFF !important;
}

.react-tel-input .flag-dropdown {
  left: 0 !important;
}

/* .input_phone_regist_t .form-control {
  margin: 0 0 0 20px;
} */

/* Ancienne config
.shop_modal_t .input_phone_regist_t .form-control {
  padding: .3rem 0px!important;
  border-radius: 10px!important;
  background: #1E2431!important;
  color: #FFF!important;
} */
.shop_modal_t .input_phone_regist_t .form-control {
  /* padding: 0.3rem .3rem!important; */
  /* border-radius: 10px!important; */
  padding: .6rem 48px !important;
  border-radius: 0 10px 10px 0 !important;
  background: #1e2431 !important;
  color: #fff !important;
  /* margin: 0 0 0 40px; */
}

.input_phone_regist_t .react-tel-input {
  margin: 0 0 0 4.5%;
  width: 88%;
}

/*Fin de la gestion du texte dans select*/
.select_t .css-g1d714-ValueContainer {
  padding: 0px 0px;
}

.select_t .css-tlfecz-indicatorContainer {
  color: #FFF !important;
}

.select_t .css-1gtu0rj-indicatorContainer {
  color: #FFF !important;
}

.select_t .css-1wa3eu0-placeholder {
  color: #FFF !important;
}

/* .select_t .css-1pahdxg-control {
  max-height: 40px!important;
  min-height: 5px;
  border-color: #1E2431!important;
  box-shadow: 0 0 0 1px #1E2431!important;
  border-radius: 10px;
  background-color: #1E2431;
} */
.select_t .css-1pahdxg-control:hover {
  border-color: #1E2431 !important;
}

.select_t .css-1pahdxg-control {
  /* max-height: 30px!important;
  min-height: 25px!important; */
  max-height: 35px !important;
  min-height: 18px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #1E2431 !important;
  border-color: #1E2431 !important;
  background-color: #1E2431;
}

.select_t .css-1uccc91-singleValue {
  color: #FFF;
  margin-left: 2%;
  top: 40%;
  transform: translateY(-40%);
}

.select_t .css-2b097c-container {
  color: black !important;
  border: 1px solid #FFF !important;
  border-radius: 5px;
}

/*svg[Attributes Style] {
    height: 6px;
    width: 6px;
}*/
/*.btn_register {
  background-color: #54A649;
  border-radius: 10px;
  padding: .3rem 1rem;
  color: #FFF;
}*/
.register_t .con_link {
  position: absolute;
  bottom: 5%;
  left: 24%;
}

.password_register_t .form-floating-label .form-control.filled+.placeholder,
.form-floating-label .form-control:focus+.placeholder,
.form-floating-label .form-control:valid+.placeholder {
  font-family: "Maison Neue Bold";
  font-size: 85% !important;
  transform: translate3d(0, -20px, 0);
  top: 0;
  opacity: 1;
  padding: .375rem 0 .75rem;
  margin: 0 0 0 .5rem;
  font-weight: 0;
  color: #1E2431;
}

.register_t button {
  font-family: Lato, sans-serif;
}

.register_t .input_t {
  display: block;
  width: 88%;
  padding: .8rem 1rem .6rem 1rem !important;
  border: 1px solid #1E2431 !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.3;
  border-radius: 10px !important;
  height: inherit !important;
  margin: 0 0 0 10%;
  background: #1E2431;
  color: #FFF;
}

.div_register_t1 {
  height: 420px !important;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

/* .div_register_t2 {
  height: 430px !important;
} */

.error_perso {
  color: #f25961 !important;
  font-size: 80% !important;
  font-weight: 600;
  font-size: 12px !important;
  margin: 0 0 0 2rem
}

/*End Register */

/*Start Login*/
.btn_login {
  background-color: #54A649;
  border-radius: 10px;
  padding: .5rem 1rem;
  color: #FFF;
  font-size: 12px;
  width: 57%;
}

.btn_register {
  background-color: #54A649;
  border-radius: 10px;
  padding: .3rem 1rem;
  color: #FFF;
  font-size: 12px;
  width: 40%;
}

.btn_register .loading_dataTable2 {
  margin: 0 0 0 -.8rem;
}

.btn_register .bnt_and_load_t {
  position: absolute;
  left: 53%;
  top: 1.1rem;
  font-size: 13px;
  margin: -2px 0 0 0;
}

.login_t .loading_dataTable2 {
  margin: 0 0 0 -.8rem;
}

.login_t .bnt_and_load_t {
  position: absolute;
  left: 35%;
  font-size: 17px;
  margin: -2px 0 0 0;
}

.login_t .div_register_t {
  background: #FFF;
  text-align: center;
  max-width: 30.666667%;
  margin: 0 0 0 3%;
  height: 400px;
  border-radius: 15px;
}

.login_t .header_f_reg_t {
  font-weight: 700;
  font-size: 22px;
  font-family: Maison Neue Bold !important;
  margin: 6% 0 6% 0 !important;
  color: #1E2431;
}

.login_t .row_t {
  margin: 4.5% 0 4.5% 0;
  text-align: left;
}

.login_t .form-floating-label .placeholder {
  padding: .375rem 0 !important;
  font-size: 90% !important;
}

.login_t .form-floating-label .form-control.filled+.placeholder {
  color: #838383 !important;
  opacity: .6;
  opacity: 1;
  font-weight: 400;
}

.login_t .form-control.filled+.placeholder {
  transform: translate3d(0, -16px, 0);
}

.login_t .input_g_2 .form-control.filled+.placeholder {
  transform: translate3d(0, -30px, 0);
}

.login_t .input_float_posit_t.form-control {
  border-radius: 0px !important;
  padding: .6rem .1rem .4rem 0 !important;
}

.login_t .perso_label {
  font-size: 12px !important;
  color: #1E2431;
}

.login_t .con_link {
  position: absolute;
  bottom: 5%;
  font: menu;
}

.login_prest_t .con_link {
  position: relative !important;
  bottom: 5%;
  margin: 5% 0 0 0;
  font: menu;
}

.login_prest_t .div_register_t {
  background: #FFF;
  text-align: center;
  max-width: 30.666667%;
  /*margin: 0 0 0 3%; */
  margin: 0 0 0 0;
  height: 400px;
  border-radius: 15px;
}

.login_prest_t .input_g_2 .form-control.filled+.placeholder {
  transform: translate3d(0, -15px, 0);
}

.login_prest_t label.error {
  position: absolute;
  margin: -.5rem 0 0 1rem;
  font-size: 12px !important;
  line-height: 13px;
  font-family: Maison Neue Bold !important;
}

.login_prest_t .form-control.filled+.placeholder {
  transform: translate3d(0, -10px, 0);
}

label.error {
  position: relative;
  margin: 0 0 0 0;
}

.forgot_pwd_t a {
  position: absolute;
  font-size: 11px;
  right: 12%;
  font-weight: 500;
  color: #54A649;
}

/*End Login*/

/*Start home Prestataire*/
.btn_create_shop_t .loading_dataTable2 {
  margin: 0 0 0 1rem;
}

.modal_shop_p_t .bnt_and_load_t {
  position: absolute;
  left: 30%;
  font-size: 15px;
  margin: 0 0 0 0;
}

.modal_shop_p_t .bnt_and_load2_t {
  position: absolute;
  left: 37%;
  font-size: 15px;
  margin: 0 0 0 0;
}

.home_prestataire .main-header {
  box-shadow: 0 0 5px #fff;
}

.home_prestataire .logo_header_size {
  width: 2.2em;
}

.logo_noir_t {
  position: absolute;
  top: 10px;
  left: 25%;
  color: #1E2431;
}

.home_prestataire .text_header_size {
  font-family: "Maison Neue Bold";
  margin: 1px;
  font-size: 22px;
}

.img_header_t {
  background-color: #E91339;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 1.7vh 0 0 0;
}

.home_prestataire .logo {
  font-family: Maison Neue Bold !important;
  left: .3vw;
  top: 1.7vh;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF !important;
}

.home_prestataire .padding_ul_t {
  padding: 1.5vh;
}

.welcome_prestataire_t {
  font-weight: 700;
  padding: 0 0 0 3%;
  font-size: 30px;
  font-family: "Maison Neue Bold";
  color: #1E2431;
}

.welcome_pres_under_t {
  padding: 0 0 0 3%;
  font-size: 15px;
  color: #1E2431;
}

.bg_footer_green_t {
  background-color: #04A763;
  margin: 13vh 0 0 0;
  padding: 10vh 10px;
}

.shop_div_color {
  border: 1px solid #83838361;
  margin: 2.5% 4.3% 0 3.5%;
  border-radius: 35px;
  line-height: 23px;
}

.shop_div_color2 {
  border: 1px solid #83838361;
  margin: 2.5% 0 0 0;
  border-radius: 35px;
  line-height: 23px;
}

.active_shop {
  background: #1E2431;
  color: #FFF !important;
}

.active_shop .shop_title_t {
  font-family: "Maison Neue Bold";
  font-size: 17px;
  color: #FFF;
}

.active_shop .shop_location_t {
  margin: 0 0 0 5%;
  font-size: 13px;
  color: #FFF;
}

.active_shop .shop_under_location_t {
  font-size: 12px;
  color: #FFF;
}

.shop_div_color2 .shop_marg_img_text {
  padding: 0 0 0 15%;
  margin: 3.5% 0 2.5% 0;
}

.shop_marg_img_text {
  padding: 0 0 0 12%;
  margin: 3.5% 0 2.5% 0;
}

.shop_under_location_t {
  font-size: 12px;
  color: #1E2431;
}

.shop_location_t {
  margin: 0 0 0 5%;
  font-size: 13px;
  color: #1E2431;
}

.shop_title_t {
  font-family: "Maison Neue Bold";
  font-size: 17px;
  color: #1E2431;
}

.round_for_img_h_prest {
  background-color: #E91339;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin: 30% 0px;
}

.round_for_usr_shop {
  background-color: #E91339;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 70% 0px;
}

.shop_marg_img_text {
  padding: 0 0 0 12%;
  margin: 3.5% 0 2.5% 0;
}

.shop_location_img {
  width: 15px;
}

.bold_t {
  font-weight: 700;
}

.btn_add_shop {
  background-color: #54A649;
  border-radius: 15px;
  padding: .6rem 1rem;
  color: #FFF;
  font-size: 16px;
  margin: 2.5% 0 0 3.3%;
}

.btn_show_cmd {
  font-family: "Maison Neue Bold";
  background-color: rgba(84, 166, 73, 0.07);
  border: 1px solid #54A649;
  border-radius: 15px;
  padding: .7rem 1rem .4rem 1rem;
  color: #54A649;
  font-size: 14px;
  margin: 2.5% 0 0 1%;
}

.btn_show_2_cmd {
  font-family: "Maison Neue Bold";
  background-color: rgba(84, 166, 73, 0.07);
  border-radius: 15px;
  padding: .6rem 1rem .5rem 1rem;
  color: #54A649;
  font-size: 12px;
  margin: 2.5% 0 0 1%;
}

.content_h_shop .btn_add_shop {
  background-color: #54A649;
  border-radius: 15px;
  padding: .6rem 1rem;
  color: #FFF;
  font-size: 16px;
  margin: 2.5% 0 2% 1%;
}

.width_t {
  width: 100%;
}

.content_h_shop {
  margin: -15px 0 0 0;
}

/*Debut Modal Prestataire*/
.prestataire_modal_t .sweet-alert {
  width: 50vw !important;
}

.prestataire_modal_t .form-floating-label .placeholder {
  top: 20px;
  left: 0px;
  font-size: 12px;
}

.shop_modal_t .form-floating-label .form-control.filled+.placeholder,
.form-floating-label .form-control:focus+.placeholder,
.form-floating-label .form-control:valid+.placeholder {
  font-family: "Maison Neue Bold";
  font-size: 85% !important;
  transform: translate3d(0, -5px, 0);
  top: 0;
  opacity: 1;
  padding: .375rem 0 .75rem;
  font-weight: 0;
  color: #1E2431;
}

.prestataire_modal_t .label_select_t {
  color: #1E2431 !important;
  opacity: .9;
}

.ajout_title {
  font-family: "Maison Neue Bold";
  font-size: 26px;
  line-height: 40px;
  color: #1E2431;
  margin: 20px 0 40px 15%;
  max-width: 150px;
}

.under_aj_title {
  font-size: 15px;
  color: #1E2431;
  margin: 0 0 35px 15%;
  line-height: 22px;
}

.green_police_t {
  color: #54A649;
}

.btn_create_shop_t .btn_add_new_shop {
  width: 86%;
  background-color: #54A649;
  border-radius: 15px;
  padding: .6rem 0 .6rem 0;
  color: #FFF;
  font-size: 16px;
  margin: 7.5% 0 0 3%;
}

.btn_create_shop_t .btn_show_new_cmd {
  font-family: "Maison Neue Bold";
  background-color: rgba(135, 135, 135, 0.17);
  width: 86%;
  border-radius: 15px;
  padding: .6rem 0 .6rem 0;
  color: #1E2431;
  font-size: 14px;
  margin: 5.5% 0 0 3%;
}

/*Begin Input File*/
.inputfile {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border: none;
  z-index: 1;
  margin: 0 0 0 -16%;
}

.a_file_t {
  margin: 0 0 0 6%;
}

.file_class_t {
  color: #54A649;
  /*max-width: 100%;*/
  /*margin: 0 100% 0 100%!important;*/
  /*padding: .5rem 1.4rem!important;*/
  /*margin: 1.5rem 1.4rem;*/
}

.file_t .btn {
  background: rgba(30, 36, 49, 0.04) !important;
  width: 79%;
  padding: .2rem 0 .2rem 0;
  font-size: 14px;
  opacity: 1;
  border-radius: 10px;
  margin: 1rem 0 0 6%;
}

.file2_t .btn {
  background: rgba(30, 36, 49, 0.04) !important;
  width: 79%;
  padding: .2rem 0 .2rem 0;
  font-size: 14px;
  opacity: 1;
  border-radius: 10px;
  margin: 1rem 0 0 6%;
}

.label_file_t {
  max-width: 250px;
  color: #8d9498;
  padding: 4% 0 0 6%;
}

.label_file_none_t {
  display: none;
  max-width: 250px;
  color: #8d9498;
  padding: 4% 0 0 6%;
}

.filename_t {
  font-family: "Maison Neue Bold";
  font-size: 13px;
  margin: 14px;
}

.fileimg_t {
  width: 80px !important;
  height: 60px !important;
}

.img_and_name_file_t {
  /*margin: 0 0 0 1.4rem;*/
  margin: 0 0 1.3rem 1rem;
  text-overflow: ellipsis;
  /* width: 225px; */
  white-space: nowrap;
  overflow: hidden !important;
}

.prestataire_modal_t .img_and_name_file_t {
  margin: 0 0 1.3rem 0rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
}

#post-media-upload {
  padding-inline-start: 0 !important;
}

.error_t {
  /*font-family: "Maison Neue Bold";*/
  color: red;
  font-size: 13px;
  margin: 0 0 0 1.4rem;
}

.dots {
  display: inline-block;
  width: 125px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/*End Input File*/
.display_options_t {
  position: absolute;
  right: 1.4rem;
  background: #FFF;
  border: 1px solid #83838361;
  margin: 0;
}

.display_t {
  display: block;
}

.hidden_t {
  display: none;
}

.supr_t {
  display: block;
  padding: 0 15px 5px;
}

.Modif_t {
  padding: 0 15px 5px;
}

.point_click_t {
  position: absolute;
  right: 20px;
  font-weight: 600;
  font-size: 17px;
}

/*FIN Modal Prestataire*/
.is_validate_shop__true_t {
  position: absolute;
  top: 0px;
  right: 1rem;
  color: #54a649;
  font-family: "Maison Neue Bold";
}

.is_validate_shop__false_t {
  position: absolute;
  top: 0px;
  right: 1rem;
  color: red;
  font-family: "Maison Neue Bold";
}

/*End home Prestataire*/

/*Begin Shop*/
.see_more_shop_t {
  display: block;
  font-size: 13px;
  text-align: center;
  margin: 25px 0 10px 0;
}

.shop_f {
  text-align: left;
  padding: 0 0 0 1rem;
}

.shop_f .welcome_pres_under_t {
  padding: 0px;
  font-size: 15px;
  color: #1E2431;
}

.btn_add_shop_right {
  background-color: #54A649;
  border-radius: 15px;
  padding: .6rem 1rem;
  color: #FFF;
  font-size: 14px;
  margin: 2.5% 0 0 1rem;
}

.shop_marg_t {
  margin: 1.5rem 0 0 0;
}

.content_h_shop .search-input {
  border: .5px solid #97979738;
  border-radius: 15px;
  height: 37px;
  width: 100%;
  margin: 1rem 0 0 0;
}

.content_h_shop .select_t .css-yk16xz-control {
  background-color: #1E2431;
  max-height: 50px !important;
  height: 40px;
  min-height: 18px;
}

.content_h_shop .select_t .css-1wa3eu0-placeholder {
  top: 40% !important;
  transform: translateY(-40%) !important;
  padding: 0 0 0 2%;
  font-size: 12px;
  padding: 0 0 0 .6rem;
}

.pad_shop_select_t {
  margin: 0 0 0 10rem;
}

.sous_cat_t {
  font-family: "Maison Neue Bold";
  margin: 1.7rem 0 0 0;
  border: .5px solid #1E2431;
  padding: .53rem .5rem;
  border-radius: 10px;
  font-size: 15px;
  display: inline-block;
  /*width: 110%;*/
}

.del_sous_cat_t {
  /*position: absolute;
  right: 9%;*/
  font-size: 20px;
  line-height: 1.2rem;
  text-align: right;
  padding: 0 1rem 0 10%;
  color: #1E2431;
  /*padding: 0 0 0 7%;*/
}

.sous_cat_t a:hover {
  color: #1E2431;
}

.card-pricing {
  text-align: left;
}

.img_prod_t {
  width: 100%;
  height: 15vh;
  object-fit: contain;
}

.btn_update_prod {
  background-color: #1E2431;
  color: #FFFFFF;
  border-radius: 5px;
}

.text_btn_upd_prod {
  font-size: 16px;
}

.product_name {
  display: block;
  color: #999999;
  font-size: 14px;
  margin: 0 0 .2rem 0;
}

.green_check {
  padding: 0 0 0 1rem;
  width: 30px;
}

.product_desc {
  display: block;
  font-family: "Maison Neue Bold";
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 .2rem 0;
}

.product_ran {
  display: block;
  margin: 0 0 2rem 0;
}

.star_yellow {
  width: 15px;
  height: 15px;
  margin: 0 .1rem 0 0;
}

.star_white {
  width: 15px;
  height: 15px;
  margin: 0 .1rem 0 0;
}

.coast_left {
  font-family: "Maison Neue Bold";
  color: #54A649;
}

.mrg_header_men_t {
  margin: 5.3rem 0 0 0 !important;
}

.btn-toggle-h-menu_t {
  font-family: "Maison Neue Bold" !important;
  font-size: 12px !important;
  margin-right: 5px !important;
  margin-left: 1px !important;
  color: #1E2431 !important;
  text-align: left !important;
  padding: 5px 0 0 0 !important;
}

.nav-toggle-h-menu_t {
  background: #FFF;
  padding: .3rem 5px .5rem 5px;
  margin: 15px 0 0 0;
  border-radius: 10px;
}

.top_bar_menu_t .select_t .css-2b097c-container {
  color: black !important;
  border: 1px solid #1E2431 !important;
}

.top_bar_menu_t .select_t .css-yk16xz-control {
  background-color: #49505745;
  max-height: 35px !important;
  min-height: 18px;
}

.top_bar_menu_t .select_t .css-2b097c-container {
  background-color: #49505745;
  border-color: #1E2431;
  border-radius: 10px !important;
  color: #1E2431 !important;
}

.top_bar_menu_t .select_t .css-1pahdxg-control {
  max-height: 35px !important;
  min-height: 25px !important;
  border-radius: 10px;
  background-color: #49505745;
}

.top_bar_menu_t .css-yk16xz-control {
  border-style: hidden !important;
  border-radius: 10px !important;
}

.top_bar_menu_t .select_t .css-1wa3eu0-placeholder {
  top: 35% !important;
  transform: translateY(-40%) !important;
  padding: 0 0 0 2%;
  font-size: 12px;
  padding: 0 0 0 10px;
}

.top_bar_menu_t .label_select_t {
  color: #495057 !important;
  font-size: 13px !important;
  opacity: 1;
  font-family: "Maison Neue Bold";
}

.top_bar_menu_t .select_t .css-tlfecz-indicatorContainer {
  color: #FFF !important;
  padding: 5px 15px 15px 15px !important;
}

.top_bar_menu_t .select_t .css-1uccc91-singleValue {
  color: #FFF;
  margin-left: 5%;
  top: 30%;
  transform: translateY(-40%);
}

.top_bar_menu_t .css-b8ldur-Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  color: #FFF !important;
  box-sizing: border-box;
}

.top_bar_menu2_t li {
  background-color: #49505745;
  padding: 5px 0 15px 20px;
  border-radius: 8px;
}

.top_bar_menu2_t li>a {
  display: inline-block;
  font-size: 13px;
  margin: .5rem 1rem 0 0;
  color: #FFF !important;
}

.top_bar_menu2_t .nav_item_width {
  /* width: 100px; */
  text-align: center;
  line-height: 5px;
  font-size: 12px;
  margin: 0 0px 0 5px;
}

.menu_tog_t {
  margin: 0 0 0 15px;
}

.search_toggle_t {
  opacity: .5;
}

.coast_right {
  position: absolute;
  right: 25px;
  text-decoration: line-through;
  color: #979797;
}

.product_t .card .card-body,
.card-light .card-body {
  padding: 1.25rem 1.25rem .1rem 1.25rem;
}

.product_t .card-pricing .card-footer {
  padding: 0 15px 10px 15px;
}

.product_t .card .card-header,
.card-light .card-header {
  padding: .1rem 1.25rem .2rem 1.25rem;
}

.product_t .card-pricing {
  padding: 10px 5px;
  max-width: 280px;
  margin: 1rem .6rem 0 1rem;
}

.product_t .card,
.card-light {
  box-shadow: none;
  border: 1px solid #EBEBEB !important;
  box-sizing: border-box;
  border-radius: 5px;
}

.shop_RP .card,
.card-light {
  box-shadow: none;
  border: 1px solid #FFF !important;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #e5e5e500;
}

.shop_modal_t .select_t .css-1wa3eu0-placeholder {
  top: 40% !important;
  transform: translateY(-40%) !important;
  padding: 0 0 0 5%;
  font-size: 12px;
}

.titleCMDList_t {
  font-family: "Maison Neue";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
  color: #1E2431;
  margin: 0 0 .1rem 2.2rem !important;
}

.oneProduct_left_t {
  color: #1E2431;
}

.page-inner {
  padding: 0px !important;
}

.paddingCMDTop_t {
  padding: 7rem 0 0 0 !important;
}

.providerCmdName_t {
  font-size: 20px;
  line-height: 110%;
  font-weight: 300;
  display: block;
}

.providerCmdBrand_t {
  font-family: "Maison Neue Bold";
  font-size: 20px;
  line-height: 155%;
  display: block;
}

.providerCmdDescription_t {
  display: block;
  margin: 0 0 .5rem 0;
  font-size: 17px;
}

.providerTilteCmdPrix_t {
  display: block;
  margin: 0 0 .3rem 0;
}

.providerCmdPrix_t {
  font-family: "Maison Neue Bold";
  display: block;
  font-size: 18px;
  color: #1E2431;
}

.oneProduct_center_t {
  max-width: 370px;
}

.providerCmdBtn_t {
  font-family: "Maison Neue Bold";
  position: relative;
  width: 218px;
  height: 57px;
  left: 136px;
  background: rgba(84, 166, 73, 0.1);
  color: #1E2431;
  /* background: #54A649;
  color: #FFF; */
  padding: 11px;
  font-size: 17px;
  border-radius: 10px;
}

.providerListCmdALBtn_t {
  display: block;
  text-align: center;
  color: #FFFFFF;
  font-size: 17px;
  background: #54A649;
  margin: 1.5rem 4.5rem 1.5rem .5rem;
  padding: 7px;
  border-radius: 10px;
}

.imgSmal_t {
  display: block;
}

.divSmalImg_t {
  max-height: 320px;
  overflow-y: auto;
}

.retourCmd_t {
  position: absolute;
  margin: 0 0 0 2rem;
  color: #1E2431;
  font-size: 16px;
  z-index: 2;
}

.imgCmd_t {
  width: 30%;
}

.circleDelivered_t {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #3EFB25;
  margin: 0 5px 0 0;
  border-radius: 50%;
}

.circleInProgress_t {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #E29705;
  margin: 0 5px 0 0;
  border-radius: 50%;
}

.DeliveredOrNot_t {
  display: inline-block;
  font-size: 15px;
  margin: 0 0 0 25px;
}

.table tbody tr:hover {
  /* background: rgba(255, 255, 255, 0.15)!important; */
  background: #99f08e !important;
  color: #FFF !important;
}

.shop_RP .table tbody tr:hover td,
.shop_RP .table tbody tr:hover td>a {
  background: #99f08e !important;
  color: #FFF !important;
}

.shop_RP .table-striped tbody tr:nth-of-type(odd) {
  /* background-color: #99f08e; */
  background-color: #FFF;
}

/* Start Round row (tr) of table */
td:first-child {
  border-top-left-radius: 30px;
}

td:last-child {
  border-top-right-radius: 30px;
}

td:first-child {
  border-bottom-left-radius: 30px;
}

td:last-child {
  border-bottom-right-radius: 30px;
}

/* End Round row (tr) of table */

.seeDetailsBtn_t {
  color: #1E2431;
}

.product_t .col,
.product_t .col-1,
.product_t .col-10,
.product_t .col-11,
.product_t .col-12,
.product_t .col-2,
.product_t .col-3,
.product_t .col-4,
.product_t .col-5,
.product_t .col-6,
.product_t .col-7,
.product_t .col-8,
.product_t .col-9,
.product_t .col-auto,
.product_t .col-lg,
.product_t .col-lg-1,
.product_t .col-lg-10,
.product_t .col-lg-11,
.product_t .col-lg-12,
.product_t .col-lg-2,
.product_t .col-lg-3,
.product_t .col-lg-4,
.product_t .col-lg-5,
.product_t .col-lg-6,
.product_t .col-lg-7,
.product_t .col-lg-8,
.product_t .col-lg-9,
.product_t .col-lg-auto,
.product_t .col-md,
.product_t .col-md-1,
.product_t .col-md-10,
.product_t .col-md-11,
.product_t .col-md-12,
.product_t .col-md-2,
.product_t .col-md-3,
.product_t .col-md-4,
.product_t .col-md-5,
.product_t .col-md-6,
.product_t .col-md-7,
.product_t .col-md-8,
.product_t .col-md-9,
.product_t .col-md-auto,
.product_t .col-sm,
.product_t .col-sm-1,
.product_t .col-sm-10,
.product_t .col-sm-11,
.product_t .col-sm-12,
.product_t .col-sm-2,
.product_t .col-sm-3,
.product_t .col-sm-4,
.product_t .col-sm-5,
.product_t .col-sm-6,
.product_t .col-sm-7,
.product_t .col-sm-8,
.product_t .col-sm-9,
.product_t .col-sm-auto,
.product_t .col-xl,
.product_t .col-xl-1,
.product_t .col-xl-10,
.product_t .col-xl-11,
.product_t .col-xl-12,
.product_t .col-xl-2,
.product_t .col-xl-3,
.product_t .col-xl-4,
.product_t .col-xl-5,
.product_t .col-xl-6,
.product_t .col-xl-7,
.product_t .col-xl-8,
.product_t .col-xl-9,
.product_t .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px !important;
}

.acide_left_shop {
  margin: -2rem 3rem 0 1rem;
}

.shop-uploadimg {
  border-bottom: 1px solid #dce1e4;
  border-top: 1px solid #dce1e4;
  padding: 10px 0;
  width: 85%;
  margin: 0 3rem 0 2rem !important;
}

.shop-uploadimg li {
  float: left;
  width: 33%;
  padding: 3px;
}

.shop-uploadimg li .uploadimg-col {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}

.shop-uploadimg li .uploadimg-col::after {
  content: '';
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s ease;
}

.shop-uploadimg li .uploadimg-col img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #eee;
}

.uploadimg-col a {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s ease;
}

.shop-uploadimg li:hover .uploadimg-col::after,
.shop-uploadimg li:hover .uploadimg-col a {
  opacity: 1;
}

.prestataire_modal_t .shop-uploadimg {
  border-bottom: 1px solid #dce1e4;
  border-top: 1px solid #dce1e4;
  padding: 10px 0;
  width: 100%;
  margin: 0 3rem 0 0rem !important;
}

#shopupload-imgList {
  margin: 0 0 0 -4rem;
}

#shopupload-imgList-principal {
  margin: 0 0 0 -4rem;
}

.removeImgIcon {
  position: absolute;
  margin: -20px 0 0 -15px;
  z-index: 2px;
}

.name_file_t {
  font-family: "Maison Neue Bold";
  position: absolute;
  margin: 3.2rem 0 0 -5rem;
  font-size: 14px;
  text-overflow: ellipsis;
  width: 85px;
  white-space: nowrap;
  overflow: hidden !important;
}

.prestataire_modal_t .name_file_t {
  font-family: "Maison Neue Bold";
  position: absolute;
  margin: 3.2rem 0 0 -5rem;
  font-size: 14px;
  text-overflow: ellipsis;
  width: 75px;
  white-space: nowrap;
  overflow: hidden !important;
}

/*Start Product Modal*/
.produit_modal_t .sweet-alert {
  width: 55vw !important;
}

.ajout_title_produit {
  font-size: 18px;
  line-height: 30px;
  color: #1E2431;
  margin: 20px 0 10px 5%;
}

.btn_create_produit_t .btn_add_new_shop {
  width: 100%;
  background-color: #54A649;
  border-radius: 15px;
  padding: .4rem 0 .4rem 0;
  color: #FFF;
  font-size: 16px;
  margin: 7.5% 0 0 3%;
}

.btn_create_produit_t .btn_show_new_cmd {
  font-family: "Maison Neue Bold";
  background-color: rgba(135, 135, 135, 0.17);
  width: 100%;
  border-radius: 15px;
  padding: .5rem 0 .4rem 0;
  color: #1E2431;
  font-size: 16px;
  margin: 13.5% 0 0 3%;
}

.produit_modal_t .file_t .btn {
  background: rgba(30, 36, 49, 0.04) !important;
  width: 83%;
  padding: .5rem 0 .5rem 0;
  font-size: 14px;
  opacity: 1;
  border-radius: 10px;
  /*margin: 3rem 0 .5rem 1rem;*/
  margin: .5rem 0 .5rem 1rem;
}

.produit_modal_t .file2_t .btn {
  background: rgba(30, 36, 49, 0.04) !important;
  width: 83%;
  padding: .5rem 0 .5rem 0;
  font-size: 14px;
  opacity: 1;
  border-radius: 10px;
  margin: 1rem 0 .5rem 1rem;
}

.background_prod_modal {
  background: rgba(84, 166, 73, 0.03);
  border-radius: 30px;
}

.produit_modal_t .input_float_posit_t.form-control.input-solid {
  background: rgb(250 252 249) !important;
}

/*End Product Modal*/

.sweet-alert>p {
  z-index: 0 !important;
}

.input_phone_regist_t.shop_add_div_t .react-tel-input {
  text-align: left;
  margin: 0 0 0 -10px !important;
  width: 93.5%;
}

.react-time-picker {
  width: 82%;
  margin: 0 0 0 2%;
}

.time_pick_t .label_select_t {
  color: #1E2431 !important;
  opacity: .9;
  margin: .5rem 0 .5rem 2%;
}

.time_pick_t .react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid #1e2431;
  border-radius: 8px;
}

.time_pick_t .react-time-picker__inputGroup {
  text-align: center;
}

.time_pick_t {
  padding: 15px 0 0 0;
}

.nav_tab_shop {
  margin: 1rem 0;
}

.nav_tab_shop a {
  color: #1E2431;
  font-size: 13px;
  font-family: "Maison Neue Bold";
}

.manu_shop_t {
  padding: 8px 2rem;
  background: rgb(84 166 73 / 9%);
  border-right: 1px solid rgb(84 166 73 / 9%);
}

.manu_shop_activ_t {
  padding: 8px 2rem;
  color: #FFF !important;
  background: #54A649;
}

.shop_title_acide_left_t {
  position: absolute;
  left: 35%;
  bottom: 2.3rem;
}

.text_file {
  font-size: 14px;
  font-family: "Maison Neue Bold";
  margin: 0 0 0 1.6rem;
  color: #1e2431;
}

.second_text_file {
  position: absolute;
  display: block;
  left: 5.5rem;
  margin: -.6rem 0 0 0;
  font-size: 10px;
  font-family: "Maison Neue Bold";
}

.file_img {
  position: absolute;
  margin: 15px 0 0 0;
  color: #1e2431;
}

.btn_update_shp {
  background-color: #1e2431;
  border-radius: 10px;
  padding: .4rem 2rem;
  color: #FFF;
  font-size: 12px;
  margin: .4rem 0 0 1rem;
}

.btn_delete_shp {
  background-color: #FFF;
  border-radius: 10px;
  padding: .4rem 1rem .3rem 0;
  color: #FFF;
  font-size: 12px;
  margin: .4rem 0 0 1%;
}

.remove_Update_shp {
  font-family: "Maison Neue Bold";
  font-size: 14px;
  color: #E23A05;
  padding: 15px;
}

.document_btn_t {
  border: 1px solid rgba(0, 0, 0, 0.28);
  padding: 1.3rem 0 1.7rem 1rem;
  margin: 10px 0 .5rem 0;
  border-radius: 10px;
}

.document_btn_t .noLoadLabel_t {
  color: #FFF;
  font-size: 11px !important;
  font-family: "Maison Neue Bold";
}

.document_t {
  margin: 1rem 0 1.3rem 0;
}

.file_rect_t_img {
  width: 13px;
  margin: 12px 1rem 0 0;
}

.document_page_t .label_file_t {
  width: 275px;
  color: #8d9498;
  padding: 4% 0 0 0;
  font-size: 12px;
}

.document_page_t .a_file_t {
  position: absolute;
  margin: -8% 0 0 16rem;
  width: 175px;
  background: rgba(30, 36, 49, 0.04) !important;
  border-radius: 10px;
}

.document_page_t .btn_add_new_shop {
  background-color: #54A649;
  border-radius: 15px;
  padding: .6rem 0 .6rem 0;
  color: #FFF;
  font-size: 16px;
  margin: -3.9rem 0 0 30rem;
  width: 1;
  display: block;
  width: 200px;
  position: absolute;
}

.document_page_t .inputfile {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border: none;
  z-index: 1;
  max-width: 150px;
  padding: 1rem;
  margin: -.6rem 0 0 -1rem;
  cursor: default;
}

.document_page_t .shop-uploadimg {
  border-bottom: 1px solid #dce1e4;
  border-top: 1px solid #dce1e4;
  padding: 10px 0;
  width: 78%;
  margin: 0 3rem 0 .3rem !important;
}

.document_page_t {
  margin: .7rem 0 0 0;
}

.role_usr_t {
  color: #54A649;
  font-size: 12px;
}

.modif_usr {
  font-family: "Maison Neue Bold";
  font-size: 12px;
  color: #1E2431;
}

.sup_user {
  font-family: "Maison Neue Bold";
  font-size: 12px;
  color: #E91339;
  margin: 0 0 0 15px;
}

.usr_marg_img_text {
  padding: 0 0 0 15%;
  margin: 3.5% 0 2.5% 0;
  line-height: 20px;
}

.usr_div_color2 {
  margin: 2.5% 0 0 0;
  border-radius: 35px;
  line-height: 23px;
}

.user_modal_t .sweet-alert {
  width: 50vw !important;
}

.user_modal_t .form-floating-label .placeholder {
  top: 35px;
  left: 0px;
  font-size: 12px;
}

.user_modal_t .label_select_t {
  color: #495057 !important;
  font-size: 13px !important;
  opacity: 1;
  font-family: "Maison Neue Bold";
}

.user_modal_t.form-floating-label .form-control.filled+.placeholder,
.form-floating-label .form-control:focus+.placeholder,
.form-floating-label .form-control:valid+.placeholder {
  font-family: "Maison Neue Bold";
  font-size: 85% !important;
  transform: translate3d(0, -5px, 0);
  top: 0;
  opacity: 1;
  padding: .375rem 0 .75rem;
  font-weight: 0;
  color: #1E2431;
}

.black_profile_img_t {
  position: absolute;
  margin: -2rem 0px 0px 2.2rem;
  color: rgb(255, 255, 255);
  width: 20px;
}

.black_profile_img_perso_t {
  position: absolute;
  margin: -2rem 0px 0px .7rem;
  color: rgb(255, 255, 255);
  width: 20px;
}

.user_modal_t .class_for_usr_style .input_float_posit_t.form-control {
  border-radius: 0px !important;
  padding: 1.8rem .1rem 0 2rem !important;
}

.user_modal_t.form-floating-label .form-control.filled+.placeholder,
.form-floating-label .form-control:focus+.placeholder,
.form-floating-label .form-control:valid+.placeholder {
  font-family: "Maison Neue Bold";
  font-size: 85% !important;
  transform: translate3d(0, -5px, 0);
  top: 0;
  opacity: 1;
  padding: .375rem 0 .75rem;
  font-weight: 0;
  color: #1E2431;
  margin-left: .5rem !important;
}

.user_modal_t .class_for_usr_style .form-floating-label .placeholder {
  margin-left: 2rem;
  /*top: 17px;*/
}

.user_modal_t .padd_r_t .form-control {
  padding: .3rem 48px !important;
  border-radius: 10px !important;
  background: #1E2431 !important;
  color: #FFF !important;
}

.t_15px {
  margin: 15px 0 0 0;
}

.padd_Bottom_15px_t {
  padding: 0 0 15px 0;
}

.font_family_a_t {
  font-family: Lato, sans-serif !important;
}

.user_modal_t .input_t {
  display: block;
  width: 88%;
  padding: .5rem 1rem .2rem 1rem !important;
  border: 1px solid #1E2431 !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.3;
  border-radius: 10px !important;
  height: inherit !important;
  margin: 0 0 0 10%;
  background: #1E2431;
  color: #FFF;
}

.checkbox_prod .perso_label {
  font-family: "Maison Neue Bold" !important;
  font-size: 14px !important;
}

.product_t .display_options_t {
  position: absolute;
  right: 1.4rem;
  background: #FFF;
  border: 1px solid #83838361;
  margin: 1.5rem 0 0 0;
}

.card-footer a:hover {
  color: #FFF !important;
  font-size: 15px !important;
}


/*End Shop*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  .footerMobil_t {
    height: 45px;
    margin: 1.5rem 0 0 0;
    background: #1E2431;
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 800px) {
  .document_page_t .a_file_t {
    position: absolute;
    margin: -12% 0 0 25%;
    width: 175px;
    background: rgba(30, 36, 49, 0.04) !important;
    border-radius: 10px;
  }

  .document_page_t .label_file_t {
    width: 155px;
    color: #8d9498;
    padding: 4% 0 0 0;
    font-size: 12px;
  }

  .document_page_t .btn_add_new_shop {
    background-color: #54A649;
    border-radius: 15px;
    padding: .6rem 0 .6rem 0;
    color: #FFF;
    font-size: 16px;
    margin: -4.9rem 0 0 23rem;
    display: block;
    width: 150px;
    position: absolute;
  }

  .document_page_t .bnt_and_load_t {
    position: absolute;
    left: 9%;
    font-size: 15px;
    margin: -10px 0 0 0;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login_t .form-floating-label .placeholder {
    padding: .375rem 0 !important;
    font-size: 70% !important;
  }

  .nav_item_width {
    /* width: 100px; */
    text-align: center;
    line-height: 5px;
    font-size: 12px;
    margin: 0 0px 0 10px;
  }

  .round_for_img_h_prest {
    background-color: #E91339;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin: 70% 0px;
  }

  .round_for_usr_shop {
    background-color: #E91339;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: .7rem 0px 0 0;
  }

  .usr_marg_img_text {
    padding: 0 0 0 20%;
    margin: 3.5% 0 0 0;
    line-height: 20px;
  }

  .padd_r_t {
    padding: 0 2.4rem 0 1rem;
  }

  .btn_create_produit_t .btn_add_new_shop {
    width: 110%;
    background-color: #54A649;
    border-radius: 15px;
    padding: .4rem 0 .4rem 0;
    color: #FFF;
    font-size: 16px;
    margin: 7.5% 0 0 3%;
  }

  .btn_create_produit_t .btn_show_new_cmd {
    font-family: "Maison Neue Bold";
    background-color: rgba(135, 135, 135, 0.17);
    width: 100%;
    border-radius: 15px;
    padding: .5rem 0 .4rem 0;
    color: #1E2431;
    font-size: 16px;
    margin: 9.5% 0 0 3%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1200px) {
  .home_pres_t .btn {
    padding: .4rem .8rem !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .document_page_t .a_file_t {
    position: absolute;
    margin: -8% 0 0 16rem;
    width: 175px;
    background: rgba(30, 36, 49, 0.04) !important;
    border-radius: 10px;
  }

  .document_page_t .label_file_t {
    width: 275px;
    color: #8d9498;
    padding: 4% 0 0 0;
    font-size: 12px;
  }

  .document_page_t .btn_add_new_shop {
    background-color: #54A649;
    border-radius: 15px;
    padding: .6rem 0 .6rem 0;
    color: #FFF;
    font-size: 16px;
    margin: -3.9rem 0 0 30rem;
    width: 1;
    display: block;
    width: 200px;
    position: absolute;
  }

  .login_t .form-floating-label .placeholder {
    padding: .375rem 0 !important;
    font-size: 90% !important;
  }

  .nav_item_width {
    /* width: 100px; */
    text-align: center;
    line-height: 5px;
    font-size: 12px;
    margin: 0 50px 0 50px;
  }

  .round_for_img_h_prest {
    background-color: #E91339;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin: 30% 0px;
  }

  .round_for_usr_shop {
    background-color: #E91339;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 70% 0px;
  }

  .padd_r_t {
    padding: 0 2.7rem 0 1rem;
  }

  .btn_create_produit_t .btn_add_new_shop {
    width: 110%;
    background-color: #54A649;
    border-radius: 15px;
    padding: .4rem 0 .4rem 0;
    color: #FFF;
    font-size: 16px;
    margin: 7.5% 0 0 3%;
  }

  .btn_create_produit_t .btn_show_new_cmd {
    font-family: "Maison Neue Bold";
    background-color: rgba(135, 135, 135, 0.17);
    width: 100%;
    border-radius: 15px;
    padding: .5rem 0 .4rem 0;
    color: #1E2431;
    font-size: 16px;
    margin: 13.5% 0 0 3%;
  }

  .usr_marg_img_text {
    padding: 0 0 0 15%;
    margin: 3.5% 0 2.5% 0;
    line-height: 20px;
  }

  .document_page_t .bnt_and_load_t {
    position: absolute;
    left: 20%;
    font-size: 15px;
    margin: 0 0 0 0;
  }
}

/*Perso Setting*/
@media only screen and (min-width: 1600px) {
  .bande_blanche_t {
    margin: 5.5rem 0 0 0;
  }
}

.disabled_anchor {
  pointer-events: none;
  cursor: default;
  background-color: #83838361;
  opacity: .6;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: white;
}

.preview_container svg {
  width: 20px !important;
  height: 20px !important;
  top: 10px !important;
}

.preview_container {
  height: 45px !important;
  line-height: 45px !important;
}

.time_picker_preview {
  height: 40px !important;
}

.react_times_button {
  width: 85%;
}

.time_picker_modal_header {
  background-color: #04A763 !important;
}

.picker_pointer .pointer_drag {
  background-color: #04A763 !important;
}

.picker_pointer {
  background-color: #04A763 !important;
}

.picker_center {
  background-color: #04A763 !important;
}

.react_times_button .wrapper {
  height: 45px !important;
}

.wrapper {
  min-height: 45px !important;
}

/* Tres important astus pour required react phone et select */
.input-required {
  position: absolute;
  border: 0;
  color: transparent;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Tres important astus pour required react phone et select */

.page-item.active .page-link {
  background-color: #04A763 !important;
  border-color: #04A763 !important;
}

.page-link:hover {
  color: #04A763 !important;
}

.page-link {
  color: #04A763;
}